export const GAME_INFO = {
  "ROCK-SCISSOR-PAPER": {
    title: "지는 가위바위보란?",
    content: `지는 게 이기는 거다!
  
  가위바위보를 했을 때 지는 사람이 
  이기는 게임입니다!
  
  만약 컴퓨터가 보를 냈을 때, 
  주먹을 낸다면 이길 수 있습니다!
  
  단, 같이 내는 것이 아니라, 
  컴퓨터가 낸 것을 보고 난 후 내면 됩니다!
  
  자, 그럼 지러 가볼까요~?!!
  `,
  },
  "COLOR-MATCH": {
    title: "컬러매치란?",
    content: `컬러매치는 단어의 색에 
알맞는 보기를 선택하는 것입니다! 

단어가 검정이라고 해도 
단어의 색이 파랑이라면 파랑을 선택해야합니다!`,
  },
};
